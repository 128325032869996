import {
  MobileHeader,
  MobileHeaderContent,
  IFanConversationSet,
  MessageList,
  ConversationBackground,
  useMessageContext,
} from '@ui/components';
import { BaseMessageInput } from '@ui/components/organisms/conversation/components/base-message-input';
import { usePostHogFeatureFlag } from '@ui/hooks';
import { ICreator } from 'app/(app)/creator.typings';
import { useMemo } from 'react';
import { twMerge } from 'tailwind-merge';

interface ICreatorConversationItem {
  creator: ICreator;
  conversationSet: IFanConversationSet;
  onClose(): void;
  onScrollToBottom?: () => void;
}
export function CreatorConversationItem({
  creator,
  conversationSet,
  onClose,
  onScrollToBottom,
}: ICreatorConversationItem) {
  const messageState = useMessageContext('fan');
  const isReplyFeatureFlagEnabled = usePostHogFeatureFlag('2-way-messages');
  const isFanAllowedToReply = conversationSet?.restrictions_list?.fans_reply;
  const isBidirectional = conversationSet?.conversation?.conversation_type === 'bidirectional';
  const enableFanReply = useMemo(
    () => isReplyFeatureFlagEnabled && isFanAllowedToReply,
    [isReplyFeatureFlagEnabled, isFanAllowedToReply],
  );
  const shouldApplyPadding = useMemo(
    () => isBidirectional && isFanAllowedToReply,
    [isBidirectional, isFanAllowedToReply],
  );
  const shouldEnableReplyInput = useMemo(
    () => isBidirectional && enableFanReply,
    [isBidirectional, enableFanReply],
  );

  return (
    <div className="flex h-full w-full flex-col bg-white lg:rounded-l-3xl">
      <MobileHeader className="lg:flex">
        <MobileHeaderContent
          title={creator.name}
          subTitle={conversationSet.conversation.name}
          onBack={onClose}
          titleClassName="text-left"
          subTitleClassName="text-left"
        />
      </MobileHeader>
      <div className="flex flex-grow flex-col overflow-y-auto rounded-t-3xl bg-black lg:flex-1 relative">
        <div className="relative flex h-full flex-col overflow-hidden">
          <ConversationBackground>
            <MessageList
              className={twMerge(
                'flex-grow overflow-y-auto pb-8 pt-8',
                shouldApplyPadding && 'pt-28',
              )}
              onScrollToBottom={onScrollToBottom}
              restrictions={conversationSet.restrictions_list}
              contextUserType="fan"
            />
            {shouldEnableReplyInput && (
              <BaseMessageInput
                onCreateMessage={messageState.onCreateMessage}
                onUpdateMessage={messageState.onUpdateMessage}
                selectedThread={conversationSet.conversation.main_thread}
                creatorId={creator.id}
                user_id={messageState.user_id}
                messageToEdit={messageState.messageToEdit}
                setMessageToEdit={messageState.setMessageToEdit}
                isCreating={messageState.isCreating}
                isPostingImage={messageState.isPostingImage}
                query={messageState.query}
                enableEmoji={false}
                enableLinkButton={false}
                enableFileUpload={false}
              />
            )}
          </ConversationBackground>
        </div>
      </div>
    </div>
  );
}
