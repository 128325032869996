'use client';

import { useState } from 'react';
import { Button, Dialog, DialogContent, DialogHeader, DialogTitle } from '@ui/components';
import { useAuthSession } from '@lib/hooks/use-auth-session';
import { BadgeCheckIcon } from 'lucide-react';
import { useRouter } from 'next/navigation';
import korcrewLogoK from '../assets/korcrewLogoK.png';

interface CreatorInfo {
  communityBadge?: string;
  name?: string;
}

interface WithAuthModalProps {
  creatorInfo: CreatorInfo | null;
  message?: string | null;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
}

const WithAuthModal = ({
  creatorInfo,
  message,
  open = false,
  onOpenChange,
}: WithAuthModalProps) => {
  const { isAuthenticated } = useAuthSession();
  const router = useRouter();

  // Handle the badge claim action
  const handleBadgeClaim = () => {
    if (!isAuthenticated && creatorInfo?.communityBadge && creatorInfo?.name) {
      onOpenChange?.(false);
      router.push(`/collect/?creator=${creatorInfo.name}&badge=${creatorInfo.communityBadge}`);
    }
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="bg-white p-0 rounded-none overflow-hidden shadow-xl sm:max-w-3xl border-0 flex flex-row z-[99999]">
        <DialogTitle className="hidden bg-orange-400" />
        {/* Left side - Gradient background with logo */}
        <div className="hidden md:flex w-1/2 bg-gradient-to-br from-orange-400 to-orange-600 items-center justify-center p-8">
          <div className="flex items-center justify-center w-32 h-32 bg-white/10 rounded-full backdrop-blur-sm">
            <img src={korcrewLogoK.src} alt="KOR Logo" width={80} height={80} />
          </div>
        </div>

        {/* Right side - Content */}
        <div className="w-full md:w-1/2 p-8">
          <DialogHeader className="mb-6 flex flex-col items-center">
            <div className="w-16 h-16 rounded-full bg-primary/10 flex items-center justify-center mb-4 shadow-inner">
              <BadgeCheckIcon className="w-8 h-8 text-primary" />
            </div>
            <h2 className="text-2xl font-semibold text-gray-900">Join KOR Space</h2>
          </DialogHeader>

          <div className="text-center mb-8">
            <p className="text-gray-600">
              {message ||
                'You need to be a member of this KOR space to use this feature. Claim this badge to become a member.'}
            </p>
          </div>

          <div className="flex flex-col gap-4">
            <Button
              type="outline"
              className="w-full py-3 px-4 rounded-lg transition-all duration-200 hover:shadow-md"
              onClick={handleBadgeClaim}
              disabled={!creatorInfo?.communityBadge || !creatorInfo?.name}>
              Claim Badge Now
            </Button>
            <Button
              type="primary"
              className="w-full py-3 px-4 rounded-lg transition-all duration-200 hover:shadow-md"
              onClick={() => {
                onOpenChange?.(false);
                router.push('/auth/');
              }}>
              Login
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default WithAuthModal;
