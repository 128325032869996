import { Badge, IFanConversationSet, Text } from '@ui/components';
import { FanIcon } from '@components/fan-icon';
import React from 'react';

interface IProps {
  conversationSet: IFanConversationSet;
  onOpenMessage(item: IFanConversationSet): void;
}

export function CreatorConversationsListItem({ conversationSet, onOpenMessage }: IProps) {
  function onClick() {
    onOpenMessage(conversationSet);
  }
  const info = conversationSet?.conversation?.miscellaneous?.badge_info;

  return (
    <div
      onClick={onClick}
      className="hover:bg-bgGrey active:bg-korLightOrange flex cursor-pointer items-center justify-between gap-3 rounded-3xl p-3 transition-colors">
      <div className="flex w-full items-center gap-3 overflow-hidden">
        <div
          className="bg-border flex h-[44px] w-[44px] items-center justify-center rounded-full"
          style={{
            background: conversationSet.mark
              ? ''
              : 'linear-gradient(153deg, #56CCF2 16.67%, #D356F2 100%)',
          }}>
          {info ? (
            <div className="h-[44px] w-[44px] overflow-hidden rounded-full">
              <Badge
                type={info?.type}
                image={info?.image}
                color={info?.color}
                size="xxxs"
                className="h-full w-full scale-[1.35]"
                showSerialNumber={false}
              />
            </div>
          ) : (
            <FanIcon type="chat" />
          )}
        </div>
        <div className="flex w-[200px] flex-col overflow-hidden text-ellipsis md:w-[300px]">
          <Text
            style={{ lineHeight: 'normal' }}
            type="m"
            className="line-clamp-1 truncate whitespace-pre-wrap font-semibold">
            {conversationSet.conversation.name}
          </Text>
          {/* {conversationSet?.text_of_last_message && conversationSet.user_name_last_message && (
            <div style={{ lineHeight: 'normal' }}>
              <span className="text-note text-xs">{conversationSet?.user_name_last_message}</span>:
              <span className="text-note ml-1 text-xs">
                {conversationSet?.text_of_last_message?.replace(/<[^>]*>/g, '')?.replace(/\n/g, ' ')}
              </span>
            </div>
          )} */}
        </div>
      </div>
      <div
        className={`mx-2 h-min rounded-lg bg-[#FDC] px-1.5 py-1 ${
          conversationSet.mark && 'invisible'
        }`}>
        <Text type="xs" className="text-primary break-normal font-bold uppercase">
          New
        </Text>
      </div>
    </div>
  );
}
