import {
  Avatar,
  EmptyScreen,
  Title,
  MobileHeader,
  MobileHeaderContent,
  IFanConversationSet,
} from '@ui/components';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ICreator } from 'app/(app)/creator.typings';
import { CreatorConversationsListItem } from './creator-conversations-list-item';
import { useEffect, useId, useMemo, useState } from 'react';
import { CreatorMessageLoader } from './creator-message-loader';
import { CreatorMessages } from '../creator-messages';
import { ViewArchivedMessages } from './creator-view-archive-button';
import { useTaskCompletionStatus } from '@lib/hooks/use-complete';
import { useAppDispatch } from '@lib/redux/store';
import { setShouldChecklistAnimate } from '@lib/redux/features/user';
import { useOnboardingState } from '../../onboarding/onboarding.state';

interface ICreatorConversationsList {
  creator: ICreator;
  conversationSets: IFanConversationSet[];
  hasMore: boolean;
  isLoading: boolean;
  hasArchivedMessages: boolean;
  onNextPage(): void;
  onClose(): void;
  onOpenMessage(message: IFanConversationSet): void;
}
export function CreatorConversationsList({
  creator,
  conversationSets,
  hasMore,
  isLoading,
  onClose,
  onOpenMessage,
  onNextPage,
  hasArchivedMessages,
}: ICreatorConversationsList) {
  const [archivedMessageOpen, setArchivedMessageOpen] = useState(false);
  const id = useId();
  const checklist = useTaskCompletionStatus(creator?.id, 'messages');
  const { onPatchTask } = useOnboardingState();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (checklist && !checklist.completed && checklist._id) {
      onPatchTask({
        task_id: checklist._id,
        completed: true,
        creator_id: creator?.id,
      })
        .then(() => dispatch(setShouldChecklistAnimate(true)))
        .catch((error) => console.error('Error patching onboarding task:', error));
    }
  }, [checklist, creator?.id]);

  function onNext() {
    onNextPage();
  }

  const showEmptyScreen = useMemo(() => {
    return !hasArchivedMessages && conversationSets.length === 0;
  }, [hasArchivedMessages, conversationSets.length]);

  return (
    <>
      <CreatorMessages
        creator={creator}
        isOpen={archivedMessageOpen}
        onClose={() => setArchivedMessageOpen(false)}
      />
      <div className="flex h-full w-full flex-col bg-white lg:rounded-l-xl">
        <MobileHeader className="lg:flex">
          <MobileHeaderContent title="Messages" onBack={onClose} />
        </MobileHeader>

        <div className="flex flex-col rounded-t-xl bg-black lg:flex-1">
          <div className="flex items-center justify-center gap-1 p-8">
            <div className="w-max">
              <Avatar size="sm" src={creator.avatar} />
            </div>
            <Title type="sm" className="text-center text-white">
              {creator.name}
            </Title>
          </div>

          <div className="h-full overflow-hidden rounded-t-xl bg-white">
            <div id={id} className="h-full max-h-[calc(75vh)] overflow-y-auto pb-14 lg:pb-8">
              <InfiniteScroll
                className="flex flex-col gap-1 p-5"
                dataLength={conversationSets.length}
                next={onNext}
                hasMore={hasMore}
                scrollableTarget={id}
                loader={<CreatorMessageLoader />}>
                {hasArchivedMessages && (
                  <ViewArchivedMessages
                    creator={creator}
                    onOpen={() => setArchivedMessageOpen(true)}
                  />
                )}
                {conversationSets.map((message, key) => (
                  <CreatorConversationsListItem
                    conversationSet={message}
                    key={key}
                    onOpenMessage={onOpenMessage}
                  />
                ))}
              </InfiniteScroll>

              {showEmptyScreen && (
                <EmptyScreen title="You have no messages yet." className="min-h-[300px]" />
              )}

              {isLoading && (
                <div className="flex flex-col gap-4 p-5">
                  <CreatorMessageLoader />
                  <CreatorMessageLoader />
                  <CreatorMessageLoader />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
