import React, { useState } from 'react';
import {
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerTrigger,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  Icon,
  Button,
} from '@ui/components';
import { useMobile } from '@ui/hooks';
import { NotificationPreference } from 'app/(app)/creator/notification-preference';

interface INotificationTriggerProp {
  creatorId: string;
  creatorName: string;
}

export const CreatorNotificationTrigger = (props: INotificationTriggerProp) => {
  const [open, setOpen] = useState(false);
  const isMobile = useMobile();

  if (isMobile) {
    return (
      <Drawer snapPoints={[1]} open={open} onOpenChange={setOpen}>
        <DrawerTrigger>
          <Button
            type="icon-secondary"
            className="h-9 w-9 bg-tabActive flex items-center justify-center p-2 rounded-full">
            <Icon type="boltSettings" stroke="#FF600D" />
          </Button>
        </DrawerTrigger>
        <DrawerContent
          hideDragHandle
          className="scrollbar-none bg-white focus-visible:outline-none mt-0 rounded-t-lg sm:rounded-lg px-4 pb-4 pt-8 h-fit max-h-[99vh] overflow-hidden">
          <DialogTitle className="p-0 m-0 h-0" />
          <DrawerDescription className="p-0 m-0 h-0" />
          <NotificationPreference creatorId={props.creatorId} creatorName={props.creatorName} />
        </DrawerContent>
      </Drawer>
    );
  }
  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger className="space-y-0">
        <Button
          type="icon-secondary"
          className="h-10 w-10 flex bg-tabActive items-center justify-center p-2 rounded-full">
          <Icon type="boltSettings" stroke="#FF600D" />
        </Button>
      </DialogTrigger>
      <DialogContent
        className="scrollbar-none bg-white rounded-lg sm:rounded-lg px-4 py-5 m-0 max-h-[95vh] overflow-hidden"
        showCloseButton={false}>
        <DialogHeader className="space-y-0 h-0 hidden">
          <DialogTitle className="p-0 m-0 h-0" />
          <DialogDescription className="p-0 m-0 h-0" />
        </DialogHeader>
        <NotificationPreference creatorId={props.creatorId} creatorName={props.creatorName} />
      </DialogContent>
    </Dialog>
  );
};
