import { createContext, useContext, useEffect, useMemo } from 'react';
import { useCableSubscription } from './hook/use-cable-subscription';
import type { EventHandler } from './types';

interface EventsContextType {
  isConnected: boolean;
  addEventHandler: <T>(eventType: string, handler: EventHandler<T>) => void;
  removeEventHandler: (eventType: string) => void;
}

const EventsContext = createContext<EventsContextType | null>(null);

export const useSocketEvents = () => {
  const context = useContext(EventsContext);
  if (!context) {
    throw new Error('useSocketEvents must be used within a SocketEventsProvider');
  }
  return context;
};

export function SocketEventsProvider({
  children,
  userId,
}: {
  children: React.ReactNode;
  userId: string;
}) {
  const { isConnected, addEventHandler, removeEventHandler } = useCableSubscription(userId);

  // Memoize the context value to prevent unnecessary re-renders
  const contextValue = useMemo(
    () => ({
      isConnected,
      addEventHandler,
      removeEventHandler,
    }),
    [isConnected, addEventHandler, removeEventHandler],
  );

  return <EventsContext.Provider value={contextValue}>{children}</EventsContext.Provider>;
}
