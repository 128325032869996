'use client';

import { Button } from '@ui/components';
import korcrewLogo from '@ui/assets/images/Logo-text-dark.png';
import { useRouter } from 'next/navigation';
import { ROUTES } from '@constants/routes.constants';
import { useInteractionGuard } from '@interaction-guard/index';

const PublicHeader = () => {
  const router = useRouter();
  const { interceptInteraction } = useInteractionGuard();

  const handleJoinCommunity = interceptInteraction((e: React.MouseEvent) => {},
  'Join this KOR Space to access exclusive content and features. Claim this badge to join.');

  return (
    <header className="flex items-center justify-between px-6 py-3 border-b border-borderGray">
      <div className="flex items-center">
        <img src={korcrewLogo.src} alt="Korcrew" className="max-w-[80px] lg:max-w-[130px]" />
      </div>

      <div className="flex items-center gap-2 lg:gap-4">
        <Button
          onClick={handleJoinCommunity}
          type="outline"
          size="small"
          className="w-fit min-w-fit">
          Join Community
        </Button>
        <Button
          onClick={() => router.push(ROUTES.auth)}
          type="primary"
          size="small"
          className="w-fit min-w-fit">
          Login
        </Button>
      </div>
    </header>
  );
};

export default PublicHeader;
