import { useState } from 'react';

interface IToggle {
  value: boolean;
  setValue(value: boolean): void;
}

export function Toggle({ value, setValue }: IToggle) {
  const toggleSwitch = () => {
    setValue(!value);
  };

  return (
    <div
      className={`relative inline-block h-8 w-12 rounded-full transition-colors duration-300  ${
        value ? 'bg-orange-500' : 'bg-gray-300'
      }`}>
      <button
        className={`absolute left-0.5 top-0.5 h-7 w-7 transform rounded-full shadow transition-transform duration-300 ${
          value ? 'translate-x-4 bg-white' : 'translate-x-0 bg-gray-600'
        }`}
        onClick={toggleSwitch}
      />
    </div>
  );
}
