import { IBadgeDisplay } from 'app/(app)/creator.typings';

export const getScrollPosition = () => {
  if (typeof window !== undefined) {
    return;
  }
};

export function rankResponses(responses: IBadgeDisplay[]) {
  if (!responses) {
    return;
  }

  const sortedResponses = responses.sort((a, b) => {
    const badgeNumberComparison = a.badgeNumber - b.badgeNumber;
    if (badgeNumberComparison !== 0) {
      return badgeNumberComparison;
    }

    return a.badgeAmount - b.badgeAmount;
  });

  return sortedResponses;
}

export const badgeEmails = [
  'jenny@wearetrival.com',
  'puppuphk@gmail.com',
  'mycatdumptruck@gmail.com',
  'crystal.fier@gmail.com',
  'slynnea@gmail.com',
  'stumptheubergeek@gmail.com',
  'scot.madsen@gmail.com',
  'michaelgjurich@gmail.com',
  'luis.peraza247@gmail.com',
  'hazzardboe@gmail.com',
  'locketlove23@gmail.com',
  'jesica.abella@gmail.com',
  'devo_griff91@hotmail.com',
  'chrisvenomfcf@gmail.com',
  'matt.gonzalez46@gmail.com',
  'katiemulcahy122@gmail.com',
  'ddodge916@gmail.com',
  'bob.actually@gmail.com',
  'rahotz18@gmail.com',
  'pouts.showery_0o@icloud.com',
  'andresvillacres1@gmail.com',
  'ckm1227@gmail.com',
  'bmaulupe@yahoo.com',
  'adoyle641@gmail.com',
  'memorypastx@gmail.com',
  'aliddane56@gmail.com',
  'reagan.masonahoy@gmail.com',
  'heather77@yahoo.com',
  'virgowaters66@yahoo.com',
  'ilove2hearulaugh@gmail.com',
  'sdavis3911@gmail.com',
  'anmeysenburg@gmail.com',
  'brookeysullivan@gmail.com',
  'bluefierydeath@gmail.com',
  'demonmage+korcrew@gmail.com',
  'brandonmicale169@gmail.com',
  'karilyn.n.brown@gmail.com',
  'jerica.berner@gmail.com',
  'christalmayorga@gmail.com',
  'kcasey1987@gmail.com',
  'easchlangen@gmail.com',
  'julia.c.hubmann@gmail.com',
  'mr0alex.ramirez@gmail.com',
  'alwaysthyme4coffee@gmail.com',
  'awheel1011@gmail.com',
  'josephac1990@gmail.com',
  'dezerae807@gmail.com',
  'sonoxcp@gmail.com',
  'eben.lowe@gmail.com',
  'silentsolo06@yahoo.com',
  'mbbell@gmail.com',
  'cassicarskadon@gmail.com',
  'wickid1.se@gmail.com',
  'cmlaporte.62@gmail.com',
  'milksguy@gmail.com',
  'dorothynicole8@gmail.com',
  'susan.moondragon@yahoo.com',
  'mintyfork3@gmail.com',
  'googasshile@gmail.com',
  'ajbarili@gmail.com',
  'jlw_92@hotmail.co.uk',
  'jake_smith1459@yahoo.com',
  'claire9donovan@gmail.com',
  'plandowski.josh@gmail.com',
  'bunnyvega2002@gmail.com',
  'kap29072@gmail.com',
  'steph.m.peacock@gmail.com',
  'racheltmcclanahan@gmail.com',
  'blueman39@live.com',
  '7arc13@gmail.com',
  'nicholeangela2@gmail.com',
  'dream.jayme@gmail.com',
  'cathrin.sargent@ca.rr.com',
  'janasandras@yahoo.com',
  'juanjauregui7@gmail.com',
  'lauraslattery01@gmail.com',
  'rudyasramos@gmail.com',
  'tracylynnsimmons@gmail.com',
  'catrbosox@gmail.com',
  'prefontupper@gmail.com',
  'cristina321@gmail.com',
  'shawnneely69@gmail.com',
  'hannah.n.kieser@gmail.com',
  'foglebr@gmail.com',
  'bdillon85@gmail.com',
  'crtylock56@gmail.com',
  'alexa.slattery@gmail.com',
  'chag.the.it@gmail.com',
  'rachelhyde96@gmail.com',
  'linzkins9@yahoo.com',
  'juliawtayloe@gmail.com',
  'daphnedumore@gmail.com',
  'madhattr1994@aol.com',
  'sarahanne0003@gmail.com',
  'kbgeorgehenderson@gmail.com',
  'angelicabaxter17@gmail.com',
  'kittykattty69@gmail.com',
  'catherine.bethany@gmail.com',
  'sorreninkorea@gmail.com',
  'avajordan.serra@gmail.com',
  'zach.hooven@gmail.com',
  'sommergrrrl@gmail.com',
  'vionbringer@gmail.com',
  'tkmiraglia@gmail.com',
  'connie.budd@gmail.com',
  'e.boswell1994@gmail.com',
  'shadowmason@yahoo.com',
  'jilleconto@gmail.com',
  'embrace.experience@gmail.com',
  'ctoler21@yahoo.com',
  'samarencibia@gmail.com',
  'shawn.bell1997@gmail.com',
  'nadeauisabelle1@gmail.com',
  'gabriellamorgan14@gmail.com',
  'adventurerjessica@gmail.com',
  'ksaq0718@gmail.com',
  'raptor711@hotmail.com',
  'amber.vanbeber@gmail.com',
  'keith.veilleux@gmail.com',
  'tomoekya@gmail.com',
  'adrianne@thewanderingheart.com',
  'jones.leanna@gmail.com',
  'ms.amayawhite@gmail.com',
  'rapowell4@gmail.com',
  'jmlarson7646@gmail.com',
  'hrcannino@gmail.com',
  '11cutekittens@gmail.com',
  'eajklose@gmail.com',
  'rodrigo.mencos@gmail.com',
  'millertimebjm@gmail.com',
  'fransvanderhoeven191@gmail.com',
  'vanytheillusionist@gmail.com',
  'gossamerdreams@hotmail.com',
  'kennoshi@gmail.com',
  'rehiggins01@gmail.com',
  'hannah.jackson.nz@gmail.com',
  'kellyanne226@hotmail.com',
  'imua2377@gmail.com',
  'tamilhuston@gmail.com',
  'connorj18@outlook.com',
  'mrgrey865@gmail.com',
  'kathleenrsturgis@gmail.com',
  'thellamaftw@gmail.com',
  'mollypolly142020@gmail.com',
  'adyches10@gmail.com',
  'kvnjmsthms@gmail.com',
  'jcarothers5442@gmail.com',
  'mlonghi5@hotmail.com',
  'anthrokim06@gmail.com',
  'alicia.geter@gmail.com',
  'andrewa5555@gmail.com',
  'hsreinhardt@gmail.com',
  'realsasquatch@duck.com',
  'aluisi@gmail.com',
  'skibunny402@gmail.com',
  'nasaboy87@yahoo.com',
  'tiffanywho11@gmail.com',
  'asherman985@gmail.com',
  'danita@danitahodges.com',
  'a2shoultz@gmail.com',
  'h.ted.wright@gmail.com',
  'brianraif@gmail.com',
  'krwhetten@gmail.com',
  'shawn.kohl2131@gmail.com',
  'akasupermanda@gmail.com',
  'heathernhughes13@gmail.com',
  'mossyrock16@gmail.com',
  'keslafystrom@gmail.com',
  'kayla.dawn0613@gmail.com',
  'labouj@yahoo.com',
  'mefuny@gmail.com',
  'korcrew@metalplates.8shield.net',
  'brian@callanan.ca',
  'rewardsandsuch13@gmail.com',
  'lesserthanthreehart@gmail.com',
  'd.j.aly.w@gmail.com',
  'melissajrob23@gmail.com',
  'ltlwolfe907@gmail.com',
  'powston57@gmail.com',
  'roadkill153@ymail.com',
  'gkarunamoorthy@gmail.com',
  'wait4f8@msn.com',
  'beaueliseolney@gmail.com',
  'karii.kosmos@gmail.com',
  'dashidora@gmail.com',
  'matt.walker921@gmail.com',
  'halfbloodpirate@gmail.com',
  'aeosyn25@gmail.com',
  'm09villegas@gmail.com',
  'izy1120@yahoo.com',
  'kimicora@gmail.com',
  'mvanbibber@gmail.com',
  'heinemanngirl@gmail.com',
  'tinmanf1@yahoo.com',
  'trent.vukodinovich@gmail.com',
  'matt.malbrough@gmail.com',
  'jed.padilla@gmail.com',
  'sessions@mailbox.org',
  'kdmohr@gmail.com',
  'tori.japanfreak@gmail.com',
  'clovebess@gmail.com',
  'tatijana.strange@gmail.com',
  'xrife.ironbeard@gmail.com',
  'ckessler2014@gmail.com',
  'k.l.briggs.06@gmail.com',
  'angelajanelee89@gmail.com',
  'bastianelle@gmail.com',
  'mollyrose95@hotmail.com',
  'krkage@gmail.com',
  'jlw31186@gmail.com',
  'luckyswims@gmail.com',
  'imjustakrab@yahoo.com',
  'caitlynfreire@gmail.com',
  'carolynkelly95@gmail.com',
  'wyvern1066@gmail.com',
  'scifibklvr@yahoo.com',
  'luiken85@gmail.com',
  'yoyo990626@gmail.com',
  'sabrina.yve.strauss@gmail.com',
  'beesc4ry@gmail.com',
  'deannetheresa@gmail.com',
  'jhamm89@gmail.com',
  'cyberdue@gmail.com',
  'luthieynne999@gmail.com',
  'ewatzen@gmail.com',
  'ariagronseth+kor@gmail.com',
  'zeebrah.love13@gmail.com',
  'jonjanakes@gmail.com',
  'tifffmarie@gmail.com',
  'xinmeijuli@gmail.com',
  'brown.alex118@gmail.com',
  'jdjfusion@gmail.com',
];

/**
 * Replaces placeholders in the route with actual values.
 * @param route The route string with placeholders (e.g., {{creatorId}}, {{communitySlug}}).
 * @param creatorId The actual creator ID to replace {{creatorId}}.
 * @param communitySlug The actual community slug to replace {{communitySlug}}.
 * @returns A route string with placeholders replaced, or null if the route is null.
 */
export const replaceRoutePlaceholders = (
  route: string | null,
  creatorId: string,
  communitySlug?: string,
): string | null => {
  if (!route) return null; // Return null if there's no route to process.

  return route
    .replace(/{{creatorId}}/g, creatorId) // Replace {{creatorId}} with the actual creator ID.
    .replace(/{{communitySlug}}/g, communitySlug); // Replace {{communitySlug}} with the actual community slug.
};
