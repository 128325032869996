import { useEffect, useMemo, useState } from 'react';
import { OnboardingState, IOnboardingTask } from '@onboarding/services';

const useFilterChecklist = (
  state: OnboardingState,
): { enabledChecklistItems: IOnboardingTask[]; hasIncompleteTask: boolean; progress: number } => {
  const [hasIncompleteTask, setHasIcompleteTask] = useState<boolean>(false);
  const enabledChecklistItems = useMemo(() => {
    const enabledByConfig = state?.progress?.tasklist?.filter((item) => item?.isEnabled);
    return enabledByConfig?.filter((item) => {
      if (item?.type === 'community') return state?.creatorHasCommunity;
      if (item?.type === 'challenges') return state?.creatorHasChallenge;
      if (item?.type === 'exclusive_content') return state?.creatorHasContents;
      return true;
    });
  }, [
    state?.progress?.tasklist,
    state?.creatorHasCommunity,
    state?.creatorHasContents,
    state?.creatorHasChallenge,
  ]);

  const progress = useMemo(() => {
    return Math.floor(
      (enabledChecklistItems?.filter((item) => item?.completed).length /
        (enabledChecklistItems?.length || 0)) *
        100,
    );
  }, [enabledChecklistItems]);

  useEffect(() => {
    const imcompleteTaskFound = enabledChecklistItems?.some((item) => !item?.completed);
    setHasIcompleteTask(imcompleteTaskFound);
  }, [state?.progress?.tasklist, enabledChecklistItems]);

  return { enabledChecklistItems, hasIncompleteTask, progress };
};

export default useFilterChecklist;
