import { useMemo, useState } from 'react';
import {
  useGetOnboardingChecklistQuery,
  useGetOnboardingProgressQuery,
  usePatchOnboardingTaskMutation,
  usePatchOnboardingTriggerMutation,
} from '@lib/redux/api/onboarding-api';
import { useAppDispatch, useAppSelector } from '@lib/redux/store';
import {
  fanConversationsAdapter,
  fanConversationsSelector,
  useGetFanCommunityConversationsQuery,
} from '@lib/redux/api/conversations-api';
import { useGetFanNotebooksQuery } from '@lib/redux/api/cms-api';
import { notebookAdapter, notebookSelector } from '@cms/services';
import { useGetChallengesQuery } from '@lib/redux/api/challenge-api';
import { challengeAdapter, challengeSelector } from '@challenge/service';
import { setChecklistPatchMutationTriggerAt, setCreatorId } from '@lib/redux/features/user';
import { OnboardingState } from '@onboarding/services';
import { useAuthSession } from '@lib/hooks/use-auth-session';

export const useOnboardingState = (): OnboardingState => {
  const { isAuthenticated } = useAuthSession();
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.user);
  const { data } = useGetChallengesQuery(
    { pageSize: 10, page: 1, creator_id: user.creator_id },
    {
      skip: !user.creator_id || !isAuthenticated,
      selectFromResult({ data, ...params }) {
        return {
          data: {
            ...data,
            challenges: challengeSelector
              .selectAll(data ? data.challenges : challengeAdapter.getInitialState())
              .sort((a, b) => {
                return new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime();
              }),
          },
          ...params,
        };
      },
    },
  );

  const { data: notebooksData } = useGetFanNotebooksQuery(
    {
      page: 1,
      page_size: 10,
      creator_id: user.creator_id,
      fan_id: user.id,
      visibility: '',
    },
    {
      skip: !user.creator_id || !user.id,
      selectFromResult({ data, ...params }) {
        return {
          data: {
            ...data,
            notebooks: notebookSelector.selectAll(
              data ? data?.notebooks : notebookAdapter.getInitialState(),
            ),
          },
          ...params,
        };
      },
    },
  );

  const {
    data: communityConversationData,
    isSuccess: isCommunityConversationSuccess,
    isLoading: isCommunityConversationLoading,
  } = useGetFanCommunityConversationsQuery(
    {
      conversation_type: 'community',
      fan_id: user.id,
      items_per_page: 10,
      page: 1,
      creator_id: user.creator_id,
    },
    {
      skip: !user.id || !user.creator_id,
      selectFromResult({ data, ...params }) {
        return {
          data: {
            ...data,
            conversations: fanConversationsSelector.selectAll(
              data ? data.conversations : fanConversationsAdapter.getInitialState(),
            ),
          },
          ...params,
        };
      },
    },
  );

  const communities = useMemo(() => {
    return communityConversationData?.conversations;
  }, [communityConversationData]);

  const {
    data: progress,
    isLoading: progressLoading,
    isSuccess,
  } = useGetOnboardingProgressQuery(
    {
      creator_id: user.creator_id,
    },
    { skip: !user.creator_id || !isAuthenticated, refetchOnMountOrArgChange: true },
  );

  const { data: checklist, isLoading: checklistLoading } = useGetOnboardingChecklistQuery(null, {
    skip: !isAuthenticated,
  });

  const [patchTask] = usePatchOnboardingTaskMutation();
  const [patchChecklistTrigger] = usePatchOnboardingTriggerMutation();

  // resource check for task list
  const creatorHasCommunity = useMemo(() => {
    return (
      isCommunityConversationSuccess &&
      !isCommunityConversationLoading &&
      communities?.length > 0 &&
      communities[0]?.last_message !== null
    );
  }, [isCommunityConversationSuccess, isCommunityConversationLoading, communities]);

  const creatorHasChallenge = useMemo(
    () => !!data?.challenges?.find((c) => c.status === 'active' || c.status === 'archived'),
    [data?.challenges],
  );

  const creatorHasContents = useMemo(() => {
    return notebooksData?.notebooks?.length > 0;
  }, [notebooksData]);

  const enabledChecklistItems = useMemo(() => {
    const enabledByConfig = progress?.tasklist?.filter((item) => item?.isEnabled);
    return enabledByConfig?.filter((item) => {
      if (item?.type === 'community') return creatorHasCommunity;
      if (item?.type === 'challenges') return creatorHasChallenge;
      if (item?.type === 'exclusive_content') return creatorHasContents;
      return true;
    });
  }, [progress?.tasklist, creatorHasCommunity, creatorHasContents, creatorHasChallenge]);

  const onPatchTask = async (args: any) => {
    try {
      await patchTask(args).unwrap();
      dispatch(setChecklistPatchMutationTriggerAt(Date.now()));
    } catch (error) {
      console.error('error', error);
    }
  };

  const onHideModalCta = () => {
    sessionStorage.removeItem('onboarding-stay-visible');
    dispatch(setChecklistPatchMutationTriggerAt(null));
  };

  return {
    checklist,
    checklistLoading,
    progress,
    creatorHasCommunity,
    creatorHasChallenge,
    creatorHasContents,
    progressLoading,
    isSuccess,
    userName: user.name,
    onPatchTask,
    creator_id: user?.creator_id,
    communitySlug: isCommunityConversationSuccess ? communities[0]?.conversation?.id : '',
    patchChecklistTrigger,
    checklistPatchMutationTriggerAt: user?.checklistPatchMutationTriggerAt,
    onHideModalCta,
  };
};
