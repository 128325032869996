import { useAppDispatch, useAppSelector } from '@lib/redux/store';
import { useOnboardingState } from '../onboarding.state';
import { OnboardingState } from '@onboarding/services';
import { setCurrentTab } from '@lib/redux/badges/badges';
import { setShouldChecklistAnimate } from '@lib/redux/features/user';
import { replaceRoutePlaceholders } from '@lib/utils/utilities';

interface useChecklistModalType {
  creator_id?: string;
  shouldChecklistAnimate: boolean;
  onAnimationAction: (value: boolean) => void;
  state: OnboardingState;
  onTabChange: (tab: string) => void;
  replacePlaceholders: (route: string, creatorId: string, communitySlug: string) => string;
  currentChecklistEvent: string;
}

export const useChecklistModal = (): useChecklistModalType => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.user);
  const state = useOnboardingState();
  const onTabChange = (tab: string) => dispatch(setCurrentTab(tab));
  const onAnimationAction = (value = false) => dispatch(setShouldChecklistAnimate(value));

  return {
    creator_id: user.creator_id,
    state,
    onTabChange,
    onAnimationAction,
    shouldChecklistAnimate: user.shouldChecklistAnimate,
    replacePlaceholders: replaceRoutePlaceholders,
    currentChecklistEvent: user.currentChecklistEvent,
  };
};
