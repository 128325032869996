import { Avatar, Title, Banner, Text, Icon, LoadingSkeleton, Note } from '@ui/components';
import { FanStats } from '@components/fan-stats';
import { useGetCreatorSocialProfileQuery } from '@lib/redux/api/creators-api';
import { twMerge } from 'tailwind-merge';
import { useGetUserQuery } from '@lib/redux/api/user-api';
import { CreatorNotificationTrigger } from 'app/(app)/creator/notification-preference';
import {
  EngagementScore,
  IEngagementScoreResponse,
} from '@ui/components/organisms/engagement-score';
import { useMobile, usePostHogFeatureFlag, usePostHogIdentity } from '@ui/hooks';
import { SubscriptionTrigger } from 'app/(app)/creator/subscriptions/_components/subscription/subscription-trigger';
import { useSearchParams } from 'next/navigation';
import { useEffect, useState } from 'react';
import { motion, useScroll, useTransform } from 'framer-motion';
import { useAuthSession } from '@lib/hooks/use-auth-session';

interface ICreatorProfile {
  creatorId: string;
  banner: string;
  notifications?: number;
  avatar: string;
  name: string;
  score?: IEngagementScoreResponse;
  badgeAmount: number;
  isLoading: boolean;
  onBio(): void;
  openMessagePanel?: () => void;
  isAboutActive: boolean;
  isMessagesLoading?: boolean;
}

export function CreatorProfile({
  creatorId,
  name,
  avatar,
  banner,
  badgeAmount,
  isLoading,
  onBio,
  isAboutActive,
  score,
  notifications,
  isMessagesLoading,
  openMessagePanel,
}: ICreatorProfile) {
  const { isAuthenticated } = useAuthSession();
  const { data: user } = useGetUserQuery();
  const {
    data: socialInfo,
    isLoading: isSocialLoading,
    isSuccess: isSocialSuccess,
  } = useGetCreatorSocialProfileQuery(creatorId, {
    skip: !creatorId,
  });
  const flagEnabled = usePostHogFeatureFlag('score-engagement');
  const flagEnabledMonetisation = usePostHogFeatureFlag('fanSubscriptionAccessEnabled');
  usePostHogIdentity({
    distinctId: user?.id,
    properties: { name: user?.name, email: user?.email, userType: 'fan' },
  });
  const searchParams = useSearchParams();
  const modalSubscriptionParam = searchParams.get('open-subscription-modal');
  const isMobile = useMobile();
  const [subscriptionModalOpen, setSubscriptionModalOpen] = useState(false);

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }

    if (modalSubscriptionParam === 'true') {
      setSubscriptionModalOpen(true);
    }
  }, [modalSubscriptionParam]);

  const { scrollY } = useScroll();

  const bannerOpacity = useTransform(scrollY, [0, 400], [1, 0]);
  const avatarRotate = useTransform(scrollY, [40, 300], [0, 180]);
  const avatarOpacity = useTransform(scrollY, [200, 400], [1, 0]);

  return (
    <div className="w-full px-2 lg:block bg-white rounded-b-xl">
      <motion.div
        className="relative"
        style={{
          opacity: bannerOpacity,
        }}>
        <Banner image={banner} type="fan" isLoading={isLoading} />
        {isAuthenticated && (
          <div className="absolute border border-white/25 hidden lg:flex items-center space-x-4 bottom-5 right-5 bg-black/50 w-fit rounded-full p-2">
            {flagEnabled && (
              <EngagementScore score={score} Title="Score" size="sm" textClassName="text-white" />
            )}
            <FanStats type="badges" value={badgeAmount} textClassName={'text-white'} />
          </div>
        )}
      </motion.div>
      <div className="pb-10 flex flex-col lg:pb-6 lg:flex-row lg:justify-start lg:items-start lg:space-x-4 lg:px-16">
        <div className="flex flex-col lg:flex-row">
          <motion.div
            className="-mt-12 flex flex-grow justify-center lg:-mt-20 lg:mb-0 lg:flex-grow-0 relative"
            style={{
              rotateY: avatarRotate,
              opacity: avatarOpacity,
              transformStyle: 'preserve-3d',
              perspective: '1000px',
            }}>
            <Avatar
              src={avatar}
              isLoading={isLoading}
              size="l"
              className="lg:h-36 lg:w-36"
              wrapperClassName="lg:h-36 lg:w-36"
            />
          </motion.div>
          <div className="mb-1 flex flex-col">
            {isLoading ? (
              <LoadingSkeleton className="mx-auto h-12 w-64 lg:ml-4 lg:mt-4" />
            ) : (
              <div className="flex flex-col items-center lg:items-start px-10 lg:px-0 lg:pt-3 gap-1 justify-center lg:justify-start">
                <div className="flex gap-2 items-center justify-center mb-2 lg:mb-0">
                  <Title
                    type="mm"
                    className="text-start  lg:text-left whitespace-nowrap overflow-hidden">
                    {name}
                  </Title>
                  {isAuthenticated && (
                    <div className="flex lg:hidden">
                      <CreatorNotificationTrigger creatorId={creatorId} creatorName={name} />
                    </div>
                  )}
                </div>
                {isAuthenticated && (
                  <div className="lg:hidden flex gap-2 items-center mb-5 justify-center">
                    {flagEnabledMonetisation && (
                      <div>
                        <SubscriptionTrigger
                          creatorName={name}
                          creator_id={creatorId}
                          triggeredFrom="creator-profile"
                          initialOpen={isMobile && subscriptionModalOpen}
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}

            <div
              className={twMerge(
                'hover:underline mb-4 lg:mb-0 active:underline flex w-full cursor-pointer items-center justify-center py-1 transition-colors lg:w-min lg:justify-start',
                isSocialSuccess && (!socialInfo || !socialInfo.bio) ? 'hidden' : '',
              )}
              onClick={onBio}>
              {isSocialLoading ? (
                <LoadingSkeleton className="h-8 w-40 " />
              ) : (
                <>
                  <Text
                    type="m"
                    className="max-w-[300px] overflow-x-hidden truncate lg:max-w-[250px]">
                    {socialInfo && socialInfo.bio}
                  </Text>
                  <Icon
                    type="back"
                    stroke=""
                    className={twMerge(
                      'group-active:[&>path]:stroke-primary inline rotate-180',
                      isAboutActive ? '[&>path]:stroke-primary' : '[&>path]:stroke-note',
                    )}
                  />
                </>
              )}
            </div>
          </div>
        </div>
        <div className="flex w-full justify-center lg:w-auto lg:justify-start lg:py-3 space-x-4 lg:space-x-5 items-center">
          <div className="hidden lg:flex flex-row items-center space-x-4">
            {isAuthenticated && flagEnabledMonetisation && (
              <SubscriptionTrigger
                creatorName={name}
                creator_id={creatorId}
                triggeredFrom="creator-profile"
                initialOpen={!isMobile && subscriptionModalOpen}
              />
            )}
            {isAuthenticated && (
              <div className="hidden lg:flex">
                <CreatorNotificationTrigger creatorId={creatorId} creatorName={name} />
              </div>
            )}
          </div>

          {isAuthenticated && (
            <div
              className="hidden lg:flex gap-2 cursor-pointer items-center !ml-4 bg-primary pl-1 py-1 pr-3 rounded-lg border"
              onClick={openMessagePanel}>
              <div className="bg-tabActive flex h-10 w-10 items-center justify-center rounded-full">
                <Icon type="message" className=" " stroke="#FF600D" />
              </div>

              <div className="flex w-fit flex-col justify-start">
                {isMessagesLoading ? (
                  <LoadingSkeleton className="w-10 h-5" />
                ) : (
                  <div className="flex items-center w-full  h-5">
                    <span className={`font-extrabold text-white ${notifications > 0 && 'mr-1'}`}>
                      {!notifications ? '' : notifications > 99 ? '99+' : notifications}
                    </span>
                    <div className={`h-min flex justify-center items-center relative`}>
                      {notifications ? (
                        <span className="flex items-center justify-center h-3 w-3 absolute right-[-18px]">
                          <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-400 opacity-95"></span>
                          <span className="relative inline-flex rounded-full h-2 w-2 bg-green-200"></span>
                        </span>
                      ) : (
                        <></>
                      )}
                      <Text
                        type="xs"
                        className="break-normal font-extrabold text-xs uppercase text-white">
                        {!notifications ? 'view' : 'new'}
                      </Text>
                    </div>
                  </div>
                )}
                <Note type="xs" className="text-white">
                  Message(s)
                </Note>
              </div>
            </div>
          )}

          {isAuthenticated && (
            <>
              <div className="block lg:hidden">
                {flagEnabled && <EngagementScore score={score} Title="Score" size="sm" />}
              </div>
              <div className="block lg:hidden">
                <FanStats type="badges" value={badgeAmount} />
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
