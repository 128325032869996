import { useRouter, useSearchParams } from 'next/navigation';
import {
  useGetChallengeBadgeRequirementsByIdQuery,
  useGetChallengeByIdQuery,
  useGetChallengesQuery,
} from '@lib/redux/api/challenge-api';
import {
  IChallenge,
  IChallengeQueryParam,
  challengeSelector,
  challengeAdapter,
  IChallengeRequirement,
} from '@challenge/service';
import { useMemo, useState, useCallback, useEffect } from 'react';
import { IBadge, useToast } from '@ui/components';
import { useAppDispatch, useAppSelector } from '@lib/redux/store';
import { IUser, setCreatorId } from '@lib/redux/features/user';
import { useCheckFanAchievementQuery, useTransferBadgeMutation } from '@lib/redux/api/badges-api';
import { ROUTES } from '@constants/routes.constants';
import { sendGAEvent } from '@next/third-parties/google';
import { ICheckFanAchievementResponse } from 'app/(badge)/claim.typings';
import {
  useMarkNotificationsByTypeMutation,
  useGetNotificationByTypeQuery,
} from '@lib/redux/api/notification-api';
import { useAuthSession } from '@lib/hooks/use-auth-session';
export interface IChallengeState {
  showEmptyState: boolean;
  isSuccess: boolean;
  isLoading: boolean;
  challenges: IChallenge[];
  singleChallenge: IChallenge | null;
  isSingleChallengeSuccess: boolean;
  selectedChallenge: IChallenge | null;
  setSelectedChallenge: React.Dispatch<React.SetStateAction<IChallenge | null>>;
  isLoadingSingleChallenge: boolean;
  isLoadingSingleChallengeRequirement: boolean;
  isFetchingSingleChallengeRequirement: boolean;
  singleChallengeBadgeRequirements: IChallengeRequirement<IBadge> | null;
  user: IUser;
  creator_id: string | null;
  isBadgeCollectionLoading: boolean;
  claimAssertionData: ICheckFanAchievementResponse | null;
  canClaimPrizeBadge: boolean;
  isUninitializedRequirements: boolean;
  isLoadingClaimAssertion: boolean;
  newNotificationsChallenge: { unread_count: number; notifications: any };
  refetchChallenges: () => void;
  refetchClaimAssertionData: () => void;
  onNextPage: () => void;
  refetchRequirements: () => void;
  onCollectBadge: (prizeBadgeId: string) => Promise<boolean>;
  markNotificationsByType: ({
    user_id,
    type,
    creator_id,
  }: {
    user_id: string;
    type: string;
    creator_id: string;
  }) => Promise<boolean>;
  gotoChallengeCompletedPage: (challengeId: string) => void;
}

export const useChallengeState = (): IChallengeState => {
  const { isAuthenticated } = useAuthSession();
  const router = useRouter();
  const toast = useToast();
  const { user } = useAppSelector((state) => state.user);
  const params = useSearchParams();
  const creator_id = params.get('id') || user.creator_id;
  const singleChallengeSlug = params.get('slug');
  const [selectedChallenge, setSelectedChallenge] = useState<IChallenge | null>(null);
  const [query, setQuery] = useState<Omit<IChallengeQueryParam, 'creator_id'>>({
    pageSize: 10,
    page: 1,
    // status: 'active',
  });
  const dispatch = useAppDispatch();
  const {
    data,
    isSuccess,
    isFetching,
    isLoading,
    refetch: refetchChallenges,
  } = useGetChallengesQuery(
    { ...query, creator_id },
    {
      skip: !creator_id || !isAuthenticated,
      selectFromResult({ data, ...params }) {
        return {
          data: {
            ...data,
            challenges: challengeSelector
              .selectAll(data ? data.challenges : challengeAdapter.getInitialState())
              .sort((a, b) => {
                return new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime();
              }),
          },
          ...params,
        };
      },
    },
  );

  const { data: newNotificationsChallenge } = useGetNotificationByTypeQuery(
    { user_id: user.id, type: 'challenge', creator_id: creator_id },
    { skip: !user.id || !creator_id },
  );

  const [markNotifications] = useMarkNotificationsByTypeMutation();

  const markNotificationsByType = useCallback(
    async ({ user_id, type, creator_id }) => {
      try {
        const result = await markNotifications({ user_id, type, creator_id }).unwrap();
        return result.success || false;
      } catch (error) {
        console.error('Error marking notifications as read:', error);
        return false;
      }
    },
    [newNotificationsChallenge?.unread_count],
  );

  const {
    data: singleChallengeData,
    isSuccess: isSingleChallengeSuccess,
    isLoading: isLoadingSingleChallenge,
  } = useGetChallengeByIdQuery(singleChallengeSlug, { skip: !singleChallengeSlug });
  const onNextPage = () => setQuery((prev) => ({ ...prev, page: prev.page + 1 }));
  const showEmptyState = useMemo(
    () => isSuccess && !isLoading && !data?.challenges.length,
    [data?.challenges.length, isSuccess, isLoading],
  );
  const {
    data: singleChallengeBadgeRequirements,
    isLoading: isLoadingSingleChallengeRequirement,
    isFetching: isFetchingSingleChallengeRequirement,
    refetch: refetchRequirements,
    isUninitialized: isUninitializedRequirements,
  } = useGetChallengeBadgeRequirementsByIdQuery(
    { id: singleChallengeSlug, fan_id: user.id },
    { skip: !singleChallengeSlug || !user.id },
  );
  const [collectBadge, { isLoading: isBadgeCollectionLoading }] = useTransferBadgeMutation();
  const onCollectBadge = async (prizeBadgeId: string) => {
    const response: any = await collectBadge({ badgeId: prizeBadgeId });

    if ('error' in response && 'data' in response.error && 'message' in response?.error?.data) {
      const message = response?.error?.data?.message;

      if (message === 'You already have this collectible, only 1 is permitted.') {
        toast(
          {
            title: 'Badge Already Collected',
            message: 'You already own this badge. Only one badge can be collected per challenge.',
          },
          {
            type: 'warning',
          },
        );

        return false;
      }
    }

    if ('error' in response) {
      toast(
        {
          title: 'Collection Failed',
          message:
            'An unexpected error occurred while attempting to collect the badge. Please try again later or contact support.',
        },
        {
          type: 'error',
        },
      );

      return false;
    }

    toast(
      {
        title: 'Success!',
        message: 'You have successfully collected your badge. Congratulations!',
      },
      {
        type: 'success',
      },
    );

    if (!isUninitializedRequirements) {
      refetchRequirements();
    }

    sendGAEvent('event', 'PrizeBadgeClaimed', { prizeBadgeId });

    return true;
  };
  const gotoChallengeCompletedPage = (challengeId: string) =>
    router.push(`${ROUTES.challengeCompleted}?slug=${challengeId}&ylap_mina=true`);
  const {
    data: claimAssertionData,
    error: claimAssertionError,
    isLoading: isLoadingClaimAssertion,
    refetch: refetchClaimAssertionData,
  } = useCheckFanAchievementQuery(
    {
      fan_id: user.id,
      creator_id: creator_id || singleChallengeData?.challenge.creator_id,
      trigger_collectible_id: singleChallengeData?.prize_badge?._id,
    },
    {
      skip: !user?.id || !singleChallengeData?.prize_badge,
    },
  );

  const canClaimPrizeBadge = useMemo(() => {
    return (
      claimAssertionData && (claimAssertionData?.achiever || claimAssertionData?.valid_subscription)
    );
  }, [claimAssertionData?.achiever, claimAssertionData?.valid_subscription]);

  useEffect(() => {
    creator_id && dispatch(setCreatorId(creator_id));
  }, [creator_id]);

  return {
    showEmptyState,
    isSuccess,
    isLoading: isLoading || isFetching,
    onNextPage,
    challenges: data ? data.challenges : [],
    singleChallenge: singleChallengeData?.challenge || null,
    isSingleChallengeSuccess,
    selectedChallenge,
    setSelectedChallenge,
    isLoadingSingleChallenge,
    singleChallengeBadgeRequirements,
    user,
    creator_id,
    isLoadingSingleChallengeRequirement,
    isFetchingSingleChallengeRequirement,
    refetchRequirements,
    onCollectBadge,
    isBadgeCollectionLoading,
    gotoChallengeCompletedPage,
    claimAssertionData,
    canClaimPrizeBadge,
    isUninitializedRequirements,
    refetchClaimAssertionData,
    isLoadingClaimAssertion,
    refetchChallenges,
    newNotificationsChallenge,
    markNotificationsByType,
  };
};
