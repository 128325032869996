import {
  INoteBookQueryParam,
  INotebookStateFan,
  notebookAdapter,
  notebookSelector,
  TNotebookStatus,
} from '@cms/services';
import { useCallback, useMemo, useState } from 'react';
import { useGetFanNotebooksQuery, useGetFanNotebookByIdQuery } from '@lib/redux/api/cms-api';
import { useGetCreatorsQuery } from '@lib/redux/api/creators-api';
import { useAppSelector } from '@lib/redux/store';
import { useRouter, useSearchParams } from 'next/navigation';
import { ROUTES } from '@constants/routes.constants';
import {
  useGetNotificationByTypeQuery,
  useMarkNotificationsByTypeMutation,
} from '@lib/redux/api/notification-api';
import { MarkNotificationsParams } from '@notification/service';
import {
  useVotePollMutation,
  useLazyGetPollResultsQuery,
  useLazyGetPollByIdQuery,
} from '@lib/redux/api/polls-api';

export const useNotebookState = (): INotebookStateFan => {
  const router = useRouter();
  const params = useSearchParams();
  const { user } = useAppSelector((state) => state.user);
  const notebook_id = params.get('notebook_id');
  const creator_id = params.get('creator_id') || params.get('id') || user.creator_id;
  const [query, setQuery] = useState<INoteBookQueryParam>({
    creator_id: creator_id,
    page: 1,
    page_size: 10,
    status: 'published',
  });
  const {
    data: notebooksData,
    isLoading,
    isSuccess: isNotebookSuccess,
    isUninitialized,
    refetch: refetchNotebooks,
  } = useGetFanNotebooksQuery(
    {
      page: query.page,
      page_size: query.page_size,
      creator_id: creator_id,
      fan_id: user.id,
      visibility: query.visibility,
    },
    {
      skip: !creator_id || !user.id,
      selectFromResult({ data, ...params }) {
        return {
          data: {
            ...data,
            notebooks: notebookSelector.selectAll(
              data ? data?.notebooks : notebookAdapter.getInitialState(),
            ),
          },
          ...params,
        };
      },
    },
  );

  const { data: creators, isLoading: creatorsLoading } = useGetCreatorsQuery();

  const {
    data: singleNotebook,
    isLoading: isLoadingSingleNotebook,
    isSuccess: isSuccessSingleNotebook,
    refetch: refetchSingleNotebook,
  } = useGetFanNotebookByIdQuery(
    {
      id: notebook_id,
      creator_id,
      fan_id: user.id,
    },
    {
      skip: !notebook_id || !creator_id || !user.id,
    },
  );

  const { data: newContentsNotifications } = useGetNotificationByTypeQuery(
    { user_id: user.id, type: 'notebook', creator_id: creator_id },
    { skip: !user.id || !creator_id },
  );

  const [markNotifications] = useMarkNotificationsByTypeMutation();

  const markNotificationsByType = useCallback(
    async ({ user_id, type, creator_id }: MarkNotificationsParams) => {
      try {
        const result = await markNotifications({ user_id, type, creator_id }).unwrap();
        return result.success || false;
      } catch (error) {
        console.error('Error marking notifications as read:', error);
        return false;
      }
    },
    [newContentsNotifications?.unread_count],
  );

  const [getPollResults] = useLazyGetPollResultsQuery();
  const [getPollById] = useLazyGetPollByIdQuery();
  const [votePoll] = useVotePollMutation();

  const pollHandlers = {
    onVotePoll: async (pollId: string, optionId: string) => {
      const response = await votePoll({
        poll_id: pollId,
        user_id: user.id,
        option_id: optionId,
      }).unwrap();
      return response;
    },
    getPollResults: async (pollId: string) => {
      const response = await getPollResults({ id: pollId, user_id: user.id });
      return response.data;
    },
    checkVoteStatus: async (pollId: string) => {
      const response = await getPollById({ id: pollId, user_id: user.id });

      return {
        poll: response?.data,
        hasVoted: response.data?.user_has_voted,
        selectedOption: response?.data?.user_selected_option?.id,
      };
    },
  };

  const onNextPage = useCallback(() => setQuery((prev) => ({ ...prev, page: prev.page + 1 })), []);
  const hasMorePages = useMemo(() => {
    return isNotebookSuccess && notebooksData ? query.page < notebooksData?.total_pages : false;
  }, [notebooksData, query.page, isNotebookSuccess]);

  const onSetFilter = useCallback((status: TNotebookStatus) => setQuery({ ...query, status }), []);

  const onBackToContentList = () => router.push(ROUTES.contents);

  return {
    onBackToContentList,
    onNextPage,
    hasMorePages,
    onSetFilter,
    notebooks: notebooksData ? notebooksData?.notebooks : [],
    isLoading,
    user_id: user.id,
    singleNotebook,
    isLoadingSingleNotebook,
    userType: 'fan',
    creators,
    creatorsLoading,
    user,
    isNotebookSuccess,
    isSuccessSingleNotebook,
    refetchNotebooks,
    refetchSingleNotebook,
    markNotificationsByType,
    newContentsNotifications,
    creator_id: creator_id,
    isUninitialized,
    pollHandlers,
  };
};
