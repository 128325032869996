import { ICreator } from 'app/(app)/creator.typings';
import { CreatorConversationsList } from './creator-conversations-list';
import { useEffect, useMemo, useState } from 'react';
import { CreatorConversationItem } from './creator-conversation-item';
import { IFanConversationSet, ModalWrapper, useMessageContext } from '@ui/components';
import { sendGAEvent } from '@next/third-parties/google';
import { useSearchParams } from 'next/navigation';
import {
  fanConversationsAdapter,
  fanConversationsSelector,
  useGetFanConversationsQuery,
  useMarkConversationMutation,
  useGetFanConversationByIdQuery,
} from '@lib/redux/api/conversations-api';
import { useAppSelector } from '@lib/redux/store';

interface ICreatorMessages {
  creator: ICreator;
  hasArchivedMessages: boolean;
  setMessagesLoading(value: boolean): void;
  onClose(): void;
}
export function CreatorConversations({
  creator,
  setMessagesLoading,
  onClose,
  hasArchivedMessages,
}: ICreatorMessages) {
  const { user } = useAppSelector((state) => state.user);
  const [page, setPage] = useState(1);
  const [conversationSet, setConversationSet] = useState<IFanConversationSet | null>(null);
  const params = useSearchParams();
  const conversationId = params.get('messageId');
  const messageState = useMessageContext('fan');
  const [markReadConversation] = useMarkConversationMutation();
  const isOpenMessage = useAppSelector((state) => state.user.user.isShowMessages);
  const { data, isSuccess, isFetching, isError, error } = useGetFanConversationsQuery(
    {
      creator_id: creator.id,
      page,
      items_per_page: 10,
      fan_id: user.id,
    },
    {
      skip: !creator.id || !user.id,
      selectFromResult({ data, ...params }) {
        return {
          data: {
            ...data,
            conversations: fanConversationsSelector.selectAll(
              data ? data.conversations : fanConversationsAdapter.getInitialState(),
            ),
          },
          ...params,
        };
      },
    },
  );
  const { data: conversation, isSuccess: isSuccessConversation } = useGetFanConversationByIdQuery(
    conversationId,
    { skip: !conversationId },
  );

  useEffect(() => {
    if (page === 0 && isFetching) {
      setMessagesLoading(true);
    }

    // gracefully handle error or success
    if (isSuccess || isError) {
      setMessagesLoading(false);
    }
  }, [isFetching, isSuccess, page, isError]);

  function onOpenMessage(conversationSet: IFanConversationSet) {
    messageState.onSetCreator(conversationSet.conversation.creator_id);
    messageState.onSetThread(conversationSet.conversation.main_thread);
    messageState.onSetConversation(conversationSet.conversation.id);
    messageState.onSetFan(conversationSet.conversation.creator_id);
    messageState.onSetConversationMiscellaneous(conversationSet.conversation?.miscellaneous);

    setConversationSet(conversationSet);
    sendGAEvent('event', 'ConversationOpened', {
      creatorId: creator.id,
      conversationId: conversationSet.conversation.id,
    });
  }

  useEffect(() => {
    if (conversationId && conversation?.conversation) {
      onOpenMessage(conversation);
      // router.replace(`/creator/?id=${creator?.id}`);
    }
  }, [conversationId, isSuccessConversation]);

  const onScrollToBottom = async () => {
    if (conversationSet && !conversationSet.mark) {
      const response = await markReadConversation({
        conversationId: conversationSet.conversation.id,
        fan_id: user.id,
        creator_id: creator.id,
      });

      if ('data' in response) {
        setConversationSet({
          ...conversationSet,
          mark: true,
        });
      }
    }
  };

  function onNextPage() {
    setPage(page + 1);
  }

  const hasMore = useMemo(() => {
    return isSuccess && data ? page < data.pages : false;
  }, [data.pages, page, isSuccess]);

  function onCloseMessage() {
    setConversationSet(null);
    messageState.resetQuery();
  }

  const isErrorDueToNotAFanError = useMemo(() => {
    const errorData = error as any;
    return errorData?.data?.error?.includes('is not a fan of creator') && errorData.status === 422;
  }, [isError, error]);

  return (
    <ModalWrapper isOpen={isOpenMessage} type="side">
      {conversationSet ? (
        <CreatorConversationItem
          onScrollToBottom={onScrollToBottom}
          creator={creator}
          conversationSet={conversationSet}
          onClose={onCloseMessage}
        />
      ) : (
        <CreatorConversationsList
          creator={creator}
          onClose={onClose}
          conversationSets={isErrorDueToNotAFanError ? [] : data.conversations}
          isLoading={isFetching}
          onOpenMessage={onOpenMessage}
          hasMore={hasMore}
          onNextPage={onNextPage}
          hasArchivedMessages={hasArchivedMessages}
        />
      )}
    </ModalWrapper>
  );
}
