import { useMemo } from 'react';
import { useGetOnboardingProgressQuery } from '@lib/redux/api/onboarding-api';
import { IOnboardingTask } from '@onboarding/services';
import { useAuthSession } from './use-auth-session';
export const TASK_TYPES = [
  'claim_badge',
  'explore_badges',
  'challenges',
  'leaderboard',
  'exclusive_content',
  'community',
  'messages',
  'profile',
] as const;
export type TaskType = (typeof TASK_TYPES)[number];

/**
 * Hook to fetch the completion status and task ID of a specific task type.
 * @param creatorId - The creator's ID to fetch onboarding progress.
 * @param taskType - The type of the task (e.g., 'claim_badge', 'explore_badges', 'challenges', 'leaderboard', 'exclusive_content', 'subscriptions', profile, 'community').
 * @returns {{ completed: boolean; _id: string } | undefined} - Task completion status and ID, or undefined if not found/loading.
 */
export const useTaskCompletionStatus = (
  creatorId: string,
  taskType: TaskType,
): { completed: boolean; _id: string } | undefined => {
  const { isAuthenticated } = useAuthSession();
  const { data, isLoading, isError } = useGetOnboardingProgressQuery(
    { creator_id: creatorId },
    { skip: !creatorId || !isAuthenticated },
  );

  const taskInfo = useMemo(() => {
    if (isLoading || isError || !data) {
      return undefined;
    }

    const task = data.tasklist.find((item: IOnboardingTask) => item.type === taskType);
    return task ? { completed: task.completed ?? false, _id: task._id } : undefined;
  }, [data, isLoading, isError, taskType]);

  return taskInfo;
};
