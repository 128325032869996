import { usePathname, useSearchParams } from 'next/navigation';
import { useAppSelector } from '@lib/redux/store';

export function useActiveSidebarLink(href: string, id?: string) {
  const searchParams = useSearchParams();
  const pathname = usePathname();
  const creatorId = searchParams.get('id');
  const currentUrl = `${pathname}${creatorId ? `?id=${creatorId}` : ''}`;
  const isActive = href ? href.replaceAll('/', '') === currentUrl.replaceAll('/', '') : false;
  const { user } = useAppSelector((state) => state.user);

  return id ? isActive || id === user?.creator_id : isActive;
}
