import React, { useEffect, useState } from 'react';
import { EasyTab, EasyTabItem, EasyTabContent, Title, useToast } from '@ui/components';
import { Toggle } from '@components/switch';
import { cookieService } from '@lib/cookie.service';
import {
  useGetNotificationPreferenceQuery,
  useUpdateNotificationPreferenceMutation,
} from '@lib/redux/api/notification-api';
import { ChecklistModal } from '@onboarding/onboarding-modal';
import { useChecklistModal } from '../onboarding/_hooks/useChecklistModal';
import useFilterChecklist from '../onboarding/_hooks/useFllterChecklist';
import { AtSign, MessageCircleMore, NotebookPen, CircleCheckBig } from 'lucide-react';
import { useOnboardingState } from '../onboarding/onboarding.state';
import { usePostHogFeatureFlag } from '@ui/hooks';

interface PreferencesModalProps {
  creatorId: string;
  creatorName: string;
}

export const NotificationPreference = ({ creatorId, creatorName }: PreferencesModalProps) => {
  const fanId = cookieService.getUserId();
  const toast = useToast();
  const onboardingState = useOnboardingState();
  const { hasIncompleteTask } = useFilterChecklist(onboardingState);
  const flagEnabledOnboard = usePostHogFeatureFlag('onboarding');

  const { data } = useGetNotificationPreferenceQuery(
    { fan_id: fanId, creator_id: creatorId },
    { skip: !creatorId || !fanId },
  );

  const [updatePreference] = useUpdateNotificationPreferenceMutation();
  const [toggleStates, setToggleStates] = useState({});

  const defaultPreferences = [
    {
      notification_type: 'mention',
      title: 'Mentions',
      description: `Receive email notifications when someone mentions you in ${creatorName}'s community.`,
      icon: <AtSign className="w-5 h-5" />,
    },
    {
      notification_type: 'message',
      title: 'Messages',
      description: `Receive email notifications when messages are sent by ${creatorName}`,
      icon: <MessageCircleMore className="w-5 h-5" />,
    },
    {
      notification_type: 'notebook',
      title: 'Content',
      description: `Receive email notifications when new content is published by ${creatorName}`,
      icon: <NotebookPen className="w-5 h-5" />,
    },
  ];

  useEffect(() => {
    if (data?.preferences) {
      const newState = {};
      data.preferences.forEach((prev) => {
        newState[prev.notification_type] = prev.enabled;
      });
      setToggleStates(newState);
    }
  }, [data]);

  const handleToggleChange = async (id: string) => {
    const newState = !toggleStates[id];
    setToggleStates((prev) => ({
      ...prev,
      [id]: newState,
    }));

    try {
      await updatePreference({
        fan_id: fanId,
        creator_id: creatorId,
        notification_type: id as 'mention' | 'message',
        enabled: newState,
      }).unwrap();
    } catch (error) {
      toast(
        {
          title: 'Error',
          message: 'Failed to update notification preference',
        },
        {
          type: 'error',
        },
      );
    }
  };

  const {
    creator_id,
    state: checklistState,
    onTabChange,
    onAnimationAction,
    shouldChecklistAnimate,
    replacePlaceholders,
    currentChecklistEvent,
  } = useChecklistModal();

  return (
    <div className="sm:max-w-[600px] min-h-[450px]">
      <div className="text-xl font-semibold mb-5">@{creatorName} Preferences</div>

      <EasyTab defaultTab="Notifications" className="w-full">
        <div className="flex gap-4 mb-4">
          <EasyTabItem label="Notifications" />
          {flagEnabledOnboard && <EasyTabItem label="Onboarding Progress" />}
        </div>

        <EasyTabContent label="Notifications" className="space-y-6">
          <div className="space-y-4">
            {defaultPreferences.map((preference) => (
              <div
                key={preference.notification_type}
                className="flex items-start justify-between p-4 rounded-sm bg-gray-50 hover:bg-gray-100 transition-colors border">
                <div className="space-y-1">
                  <div className="flex items-center space-x-2">
                    <span className="text-xl">{preference.icon}</span>
                    <Title type="xs" className="font-semibold text-[14px]">
                      {preference.title}
                    </Title>
                  </div>
                  <p className="text-[13px] max-w-[250px] md:max-w-[350px] text-gray-600">
                    {preference.description}
                  </p>
                </div>
                <Toggle
                  value={toggleStates[preference.notification_type]}
                  setValue={() => handleToggleChange(preference.notification_type)}
                />
              </div>
            ))}
          </div>
        </EasyTabContent>

        {flagEnabledOnboard && (
          <EasyTabContent label="Onboarding Progress" className="min-h-[300px] mt-[30px]">
            <div className="space-y-4">
              <div className="p-4 rounded-sm border bg-gray-50">
                <h3 className="font-medium mb-2">Onboarding Checklist</h3>
                <p className="text-sm text-gray-600 mb-4">
                  {hasIncompleteTask
                    ? `You have some pending tasks to complete. Review your onboarding checklist for ${creatorName}!`
                    : "Congratulations! You've completed all onboarding tasks."}
                </p>
              </div>
              {!hasIncompleteTask && (
                <div className="flex items-center justify-between p-4 rounded-lg bg-green-50 text-green-800 border border-green-400">
                  <div className="flex items-center space-x-2">
                    <span className="text-xl">🎉</span>
                    <p className="font-medium">All tasks completed!</p>
                  </div>
                  <ChecklistModal
                    disableAbsolute={true}
                    style={{ bottom: '9%' }}
                    creator_id={creator_id}
                    state={checklistState}
                    onTabChange={onTabChange}
                    onAnimationAction={onAnimationAction}
                    shouldChecklistAnimate={shouldChecklistAnimate}
                    replacePlaceholders={replacePlaceholders}
                    currentChecklistEvent={currentChecklistEvent}
                  />
                </div>
              )}
              {hasIncompleteTask && (
                <div className="flex items-center justify-between p-4 rounded-lg bg-orange-50 border text-orange-800 border-orange-200">
                  <div className="flex items-center space-x-2">
                    <CircleCheckBig />
                    <p className="font-medium">Complete your task!</p>
                  </div>
                  <ChecklistModal
                    disableAbsolute={true}
                    style={{ bottom: '9%' }}
                    creator_id={creator_id}
                    state={checklistState}
                    onTabChange={onTabChange}
                    onAnimationAction={onAnimationAction}
                    shouldChecklistAnimate={shouldChecklistAnimate}
                    replacePlaceholders={replacePlaceholders}
                    currentChecklistEvent={currentChecklistEvent}
                  />
                </div>
              )}
            </div>
          </EasyTabContent>
        )}
      </EasyTab>
    </div>
  );
};
