import { Text } from '@ui/components';
import { Megaphone, ChevronRight } from 'lucide-react';
import {
  messagesAdapter,
  messagesSelector,
  useGetMessagesQuery,
} from '@lib/redux/api/messages-api';
import { ICreator } from 'app/(app)/creator.typings';
import { useMemo } from 'react';

interface IProps {
  onOpen(): void;
  creator: ICreator;
}
export function ViewArchivedMessages({ onOpen, creator }: IProps) {
  const { data } = useGetMessagesQuery(
    {
      creatorId: creator.id,
      page: 0,
      pageSize: 10,
    },
    {
      skip: !creator.id,
      selectFromResult({ data, ...params }) {
        return {
          data: {
            ...data,
            messages: messagesSelector.selectAll(
              data ? data.messages : messagesAdapter.getInitialState(),
            ),
          },
          ...params,
        };
      },
    },
  );

  const unRead = useMemo(() => {
    if (data?.messages) {
      return data?.messages?.some((item) => !item?.isRead);
    }
    return false;
  }, [data?.messages]);

  return (
    <div
      onClick={onOpen}
      className="hover:bg-bgGrey active:bg-korLightOrange flex cursor-pointer items-center justify-between gap-3 rounded-3xl p-3 transition-colors">
      <div className="flex w-full items-center gap-3 overflow-hidden">
        <div
          className="bg-border flex h-[44px] w-[44px] items-center justify-center rounded-full"
          style={{
            background: 'linear-gradient(153deg, #56CCF2 16.67%, #D356F2 100%)',
            height: 44,
            width: 44,
          }}>
          <Megaphone className="" />
        </div>
        <div className="flex flex-col overflow-hidden text-ellipsis md:w-[300px]">
          <Text type="m" className="line-clamp-1 truncate whitespace-pre-wrap font-semibold">
            New Messaging Interface Launched!
          </Text>
          <p className="text-note font-inter text-sm font-normal">
            You can still access your older messages.
          </p>
        </div>
      </div>

      <div className={`mx-2 h-min rounded-lg bg-[#FDC] px-1.5 py-1 ${!unRead && 'invisible'}`}>
        <Text type="xs" className="text-primary break-normal font-bold uppercase">
          New
        </Text>
      </div>

      <div className={`rounded-lg bg-[#FDC] p-1`}>
        <ChevronRight className="" />
      </div>
    </div>
  );
}
